import { useEffect, useState, useContext } from 'react'
import { formatDistanceToNow } from 'date-fns'
import OwnerSelect from '../leads/OwnerSelect'
import StateSelect from '../leads/StateSelect'
import { updateLeadUserId, updateLeadState } from '../leads/utils'
import EditableItem from '../leads/EditableItem'
import { LeadClusterContext } from '../contexts'
import RequiresApproval from '../leads/RequiresApproval'
const timeAgo = (date) => {
  if (!date) return 'invalid date'

  return formatDistanceToNow(new Date(date), { addSuffix: true })
}

const UserAssignment = () => {
  const { leadCluster } = useContext(LeadClusterContext) || {}
  const [assignedUserId, setAssignedUserId] = useState(leadCluster.user_id)

  const onOwnerChange = (leadId, userId) => {
    setAssignedUserId(userId)
    updateLeadUserId(leadId, userId, 'LeadCluster')
  }

  return (
    <div className="px-3">
      <div className="small mb-1"><b>Assigned to:</b></div>
      <OwnerSelect
        hit={leadCluster}
        onOwnerChange={onOwnerChange}
        selectedValue={assignedUserId}
      />
    </div>
  )
}

function LeadDetails({ status }) {
  const { leadCluster, setLeadCluster } = useContext(LeadClusterContext)

  let [contact, setContact] = useState(leadCluster?.contact)

  useEffect(() => {
    setContact(leadCluster?.contact)
  }, [leadCluster])

  const onStateChange = (leadId, newState) => {
    return updateLeadState(leadId, newState)
      .then(res => res.json())
      .then(res => {
        setLeadCluster(res)
      })
  }

  return (
    <div className="col-lg-3">
      <div className="bg-white border-left h-100">
        <div className="pt-3 px-3">
          <div className="mr-auto small mb-1 d-flex">
            <b>Lead Status:</b>
            {leadCluster?.pipeline && (
              <span className="small text-secondary ml-auto">
                Pipeline: {leadCluster.pipeline?.name}
              </span>
            )}
          </div>
          {leadCluster && leadCluster?.lead_state && <StateSelect
            hit={leadCluster}
            attribute="lead_state"
            onStateChange={onStateChange}
            selectedValue={leadCluster.lead_state}
            leadStatusOptions={leadCluster.lead_status_options}
          />}
          {leadCluster && leadCluster.requires_approval === true && <RequiresApproval />}
          {leadCluster?.approver && (
            <div className="small text-secondary mt-1">
              <div className="d-flex align-items-center">
                <div className="mr-auto">Approved by:</div>
                <div>{leadCluster.approver.name}</div>
              </div>
            </div>
          )}
        </div>
        <hr />
        {leadCluster && status === 'completed' && (
          <UserAssignment />
        )}
        <hr />
        <div className="pt-1 px-3">
          <div className="d-flex align-items-center">
            <div className="mr-auto"><b>Created:</b></div>
            {leadCluster && <div className="text-secondary text-right">
              {timeAgo(leadCluster.first_lead_created_at)}
              <div className="small"> ({
                new Date(leadCluster.first_lead_created_at)
                  .toLocaleString('en-AU', {
                    timeZone: 'Australia/Brisbane',
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })
              })</div>
            </div>}
          </div>
        </div>
        {/* {leadCluster?.test_drive_status && features.test_drives && (
          <>
            <hr />
            <div className="pt-1 px-3">
              <div className="d-flex align-items-center">
                <div className="mr-auto"><b>Test Drive:</b></div>
                <LeadTestDrive features={features} />
              </div>
            </div>
          </>
        )} */}
        <hr />
        <div className="pt-1 px-3">
          <div className="d-flex align-items-center">
            <div className="mr-auto"><b>Location:</b></div>
            <div className="text-secondary text-right">{leadCluster?.location?.name}</div>
          </div>
        </div>
        {/* {lead?.source && (
          <>
            <hr />
            <div className="pt-1 px-3">
              <div className="d-flex align-items-center">
                <div className="mr-auto"><b>Source:</b></div>
                <LeadSourceBadge />
              </div>
            </div>
          </>
        )} */}
        <hr />
        <div>
          <EditableItem field="first_name" label="First Name" value={contact?.first_name} setContact={setContact} />
          <EditableItem field="last_name" label="Last Name" value={contact?.last_name} setContact={setContact} />
          <EditableItem field="phone_number" label="Phone" value={contact?.phone_number} setContact={setContact} />
          <EditableItem field="email" label="Email" value={contact?.email} setContact={setContact} />
          <EditableItem field="postcode" label="Postcode" value={contact?.postcode} setContact={setContact} />
          <EditableItem field="business_name" label="Business Name" value={contact?.business_name} setContact={setContact} />
          <EditableItem
            field="date_of_birth"
            placeholder='dd/mm/yyyy'
            label="DOB"
            value={contact?.date_of_birth}
            setContact={setContact}
          />
        </div>
        <hr />
        {leadCluster && (
          <div className="px-3 pt-1">
            <a href={`/dealerships/${leadCluster.dealership_id}/contacts/${leadCluster.contact_id}`} className="btn btn-outline-secondary btn-block">
              <i className="fa fa-user mr-2"></i>
              View Contact
            </a>
            {/* {features.test_drives && lead?.test_drive_status && !lead.car?.test_drive_in_progress_lead_id && (
              <TestDriveButton />
            )}
            <a href={`/dealerships/${lead.dealership_id}/leads/${lead.id}/edit`} className="btn btn-outline-secondary btn-block">
              <i className="fa fa-edit mr-2"></i>
              Edit
            </a>
            {lead?.paymentRequestEligible && (lead?.car) && (
              <RequestPayment notification={notification} />
            )} */}
          </div>
        )}
      </div>
    </div>
  )
}

export default LeadDetails
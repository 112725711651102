import { useEffect, useState } from 'react'
import Select from 'react-select'
import Loading from '../Loading'
import KanbanBoard from '../leadCluster/Kanban/KanbanBoard'
import { useParams } from 'react-router-dom'

let periodOptions = [
  { label: '7 days', value: 7 },
  { label: '14 days', value: 14 },
  { label: '30 days', value: 30 },
  { label: '60 days', value: 60 },
  { label: '90 days', value: 90 }
]

const CustomHitsBeta = () => {
  let [data, setData] = useState(false)
  let [onlyMe, setOnlyMe] = useState(false)
  let [users, setUsers] = useState([])
  let [loading, setLoading] = useState(false)
  let [categories, setCategories] = useState([])
  let [selectedCategories, setSelectedCategories] = useState([])
  let [selectedLocations, setSelectedLocations] = useState([])
  let [timePeriodDays, setTimePeriodDays] = useState(30)
  let { pipelineId } = useParams()

  const refreshData = () => {
    setLoading(true)
    let url = `/dealerships/${window.dealer_slug}/leads/trello_data.json?only_me=${onlyMe}&user_ids=${users}&categories=${selectedCategories}&location_ids=${selectedLocations}&time_period_days=${timePeriodDays}`

    if (pipelineId !== undefined && pipelineId !== null) {
      url += `&pipeline_id=${pipelineId}`;
    }

    fetch(url)
      .then(response => response.json())
      .then(data => {
        setData(data)
        if (categories.length === 0) setCategories(data.categories?.sort((a, b) => a.localeCompare(b)))
        setLoading(false)
      })
  }

  useEffect(() => {
    refreshData()
  }, [onlyMe, users, selectedCategories, selectedLocations, timePeriodDays])

  if (!data) return (
    <div className="py-3">
      <Loading />
    </div >
  )

  const toggleValue = () => {
    setOnlyMe(!onlyMe)
  }

  return (
    <div className="py-3">
      {loading ? (
        <p className="mb-2 text-secondary">Loading...</p>
      ) : (
        <p className="mb-2 text-secondary">Only showing leads from last {timePeriodDays} days</p>
      )}
      <div className="box p-2 d-flex align-items-center">
        <div className="custom-control custom-switch mr-3">
          <input
            type="checkbox"
            className={"custom-control-input" + (loading ? " disabled" : "")}
            checked={onlyMe}
            onChange={toggleValue}
            disabled={loading}
            id={`switch-only-me`}
          />
          <label
            className="custom-control-label"
            htmlFor={`switch-only-me`}
          >
            Only show my leads
          </label>
        </div>
        <div className="ml-auto" style={{ marginRight: '10px' }}>
          <div className="d-flex">
            {categories && <div className="mr-2">
              <Select
                options={categories?.map(category => ({ value: category, label: category }))}
                onChange={(e) => setSelectedCategories(e.map(e => e.value))}
                isMulti
                placeholder="Filter by Category"
              />
            </div>}
            {data.locations && <div className="mr-2">
              <Select
                options={data.locations.map(location => ({ value: location.id, label: location.name }))}
                onChange={(e) => setSelectedLocations(e.map(e => e.value))}
                isMulti
                placeholder="Filter by Location"
              />
            </div>}
            <Select
              options={periodOptions}
              placeholder="Filter by period"
              onChange={selection => setTimePeriodDays(selection.value)}
              value={periodOptions.filter(o => o.value === timePeriodDays)}
              className="mr-2"
            />
            <Select
              options={data.users.map(user => ({ value: user.id, label: user.name }))}
              onChange={(e) => setUsers(e.map(user => user.value))}
              isMulti
              placeholder="Filter by user"
            />
          </div>
        </div>
      </div>
      <KanbanBoard columns={data.columns} leads={data.lead_clusters} refreshData={refreshData} />
    </div>
  )
}

export default CustomHitsBeta
